*{
    padding: 0;
    margin: 0;
}
.mainPage{
    width: 80%;
    display: flex;
    /* justify-content: center; */
    margin-left: 1rem;
}
.subPage{
    background-color: #fff;
    width: 90%;
}
.Heading{
    font-size: 1.7rem;
    text-align: center;
}
.borderBlue{
    width: 10rem;
    display: block;
    /* margin-left: auto; */
    margin-right: auto;
    margin-bottom: -2px;
    border-style: solid;
    border-color: rgb(0, 175, 239);
    border-image: initial;
    border-width: 0px 0px 2px;
    z-index: 1;
}
.borderGrey{
    border-style: solid;
    border-color: rgb(220, 220, 220);
    border-image: initial;
    border-width: 0px 0px 2px;
    width: 100%;
    margin-bottom: 20px;
    margin-left: 1rem;
}

/* mobile version */
@media screen and (min-width:320px) and (max-width:450px){
    *{
        padding: 0;
        margin: 0;
    }
    .mainPage{
        width: 90%;
        /* background-color: #cf1111; */
        display: flex;
        justify-content: center;
        margin-top: 1rem;
    }
    .subPage{
        background-color: #fff;
        width: 100%;
        /* padding-left: 2rem; */
    }
}

/* tablet version */
@media screen and (min-width:450px) and (max-width:900px){
    *{
        padding: 0;
        margin: 0;
    }
    .mainPage{
        width: 90%;
        /* background-color: #cf1111; */
        display: flex;
        justify-content: center;
        margin-top: 1rem;
    }
    .subPage{
        background-color: #fff;
        width: 70%;
        /* padding-left: 2rem; */

    }
}