*{
    padding: 0;
    margin: 0;
}
.MainPage{
    width: 100%;
    /* background-color: #db2424; */
    display: flex;
    justify-content: center;
    padding: 1rem;
}
.Subpage{
    width: 10   0%;
    background-color: #fff;
}
.header>h4{
    font-size: 1.2rem;
    text-align: center;
    color: rgb(0, 175, 239);
}
.pages{
    width: 82%;
    padding: 1rem;
    justify-content: space-between;
    gap: 20px;
    display: flex;
}
.subpage1{
    width: 50%;
    /* background-color: #fff; */
}
.subpage2{
    width: 50%;
    /* background-color: #136183; */
}
.text{
    font-weight: 600;
    font-size: 1rem;
}
.smtext{
    font-size: 0.8rem;
}

/* mobile version */
@media screen and (min-width:320px) and (max-width:450px){
    *{
        padding: 0;
        margin: 0;
    }
    .MainPage{
        width: 100%;
        /* background-color: #db2424; */
        display: flex;
        justify-content: center;
        padding: 1rem;
    }
    .Subpage{
        width: 100%;
        background-color: #fff;
    }
    .subpage1{
        width: 100%;
    }
    .subpage2{
        width: 100%;
    }
    .text{
        font-weight: 600;
        font-size: 0.8rem;
    }
    .smtext{
        font-size: 0.7rem;
    }
    .pages{
        width: 100%;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        /* gap: 1rem; */
    }
}

/* tablet varsion */
@media screen and (min-width:450px) and (max-width:900px) {
    *{
        padding: 0;
        margin: 0;
    }
    .MainPage{
        width: 100%;
        /* background-color: #db2424; */
        display: flex;
        justify-content: center;
        padding: 1rem;
    }
    .Subpage{
        width: 100%;
        background-color: #fff;
    }
    .pages{
        width: 100%;
        padding: 1rem;
        /* background-color: blueviolet; */
        display: flex;
        gap: 1rem;
    }
    .subpage1{
        width: 48%;
        /* background-color: #fff; */
    }
    .subpage2{
        width: 48%;
        /* background-color: #136183; */
    }
    .text{
        font-weight: 600;
        font-size: 0.95rem;
    }
    .smtext{
        font-size: 0.75rem;
    }
}