.bannerSlider {
  width: 85.566%;
}

.achievers {
  width: 14.434%;
}
/* Nishitha */
.trending_fixed{
  width:23rem;
  background-color:white;
  z-index:999;
  /* border:1px solid red; */

}
.detailsContainer{
  width:73% !important;
}
.Main{
  width:76% !important;
}

.invisible {
  transition: opacity 0.5s ease;
  opacity: 0;
}
.product_description{
width:90% !important;
padding:1rem;
 text-align:justify;
}
.product_description_inner1{
width:90%;
margin-top:10px;
}
.product_description_inner2{
  width:80%;
  margin-top:10px;
}

/* .header-nav .manmobile{
  display: none !important;
}
@media (max-width: 768px) {
  .header-nav .manmobile{
    display:flex;
  }
} */
.swiper-button-next,
.swiper-button-prev {
  width: 30px !important;
  height: 20px !important;
  color: #009900 !important;
  overflow: hidden;
}

.hoveredImage {
  position: absolute;
  z-index: 999;
  /* Other styles for the hovered image */
  top: 0;
  /* Adjust as needed */
  left: 0;
  /* Adjust as needed */
}

.noproductconta {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noproductconta .noproductImaage {
  width: 25%;
  height: 80%;
}

.menumainhomebar {
  position: relative;
}

.menumainhomebar .mobilemenubar {
  display: none;
  position: absolute;
}

.header-nav,
.header-nav-middle {
  z-index: 99;
}

.body {
  overflow: hidden;
}

/* .body .row .view .title-name,.price{
  font-weight: 550;
  font-size: 25px;
} */
.body .row .view .text {
  font-weight: 500;
}

.body .row .view .text-left {
  font-size: 15px;
  font-family: "Public Sans", sans-serif;
  line-height: 22px;
}

.body .row .view .brand-list h6 {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}

.load {
  display: flex;
  justify-content: center;
  align-items: center;
}

.clasman {
  text-decoration: none;
}

.homeSlider {
  /* width: 100%; */
}

/* .homeSlider .homeSliderImg {
	width: 100%;
} */

.homeSlider .homeSliderImg img {
  /* width: 100%;
	height: 100%; */
  cursor: pointer;
}

.swiperimg {
  border-radius: 10px;
  margin: 10px;
  width: 95%;
}

.mySwiper {
  margin: 0 20px 20px 20px;
}
 .swiper{
  margin: 0;
 }

.faicon {
  font-size: 25px;
  cursor: pointer;
}

.hovfaicon {
  font-size: 20px;
  cursor: pointer;
}

.myimgmy:hover img {
  cursor: pointer;
}

.secomain {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.secomain .secconta {
  width: 60%;
  height: 8vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.secconta ul {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  width: 100%;
}

.secconta ul li {
  list-style: none;
  color: #111;
  font-weight: 501;
  padding: 0 4vh;
}

.secconta ul li:hover {
  color: #0d6efd;
  transition: 0.5s;
}

.faicoarrow {
  font-size: 17px;
  margin-top: 2px;
}

.bottompadd {
  margin-bottom: 6vh;
}

.bottompadd1 {
  margin-bottom: 14vh;
}

.serchsugist {
  display: none;
}

.search-box .serchsugist {
  display: block;
  position: absolute;
  z-index: 9999;
  border: 1px solid #20c997;
  background-color: aliceblue;
  width: 48.2%;
  padding: 0.5%;
  border-radius: 4px;
}

.serchsugist h6:nth-child(2) {
  color: #20c997;
  font-weight: 502;
}

/* .serchsugist ul .suglink .liclas:nth-child(odd){
  background-color: Lightgreen;
} */
.serchsugist ul .suglink .liclas:hover {
  background-color: Lightgreen;
}

/* ===============SelectCatSlider start ============== */
.blur-up.lazyloaded {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-bottom: 15px; */
}

footer .main-footer li {
  cursor: pointer;
}

footer .left-footer .text {
  color: #4889be;
}

.order-unsuccess {
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-unsuccess .UnsuccessImg {
  width: 50%;
}

.order-unsuccess .UnsuccessImg img {
  width: 20%;
}

/* .unsuccess h3,h5,h6{
  color: red;
} */
.pending h3 {
  font-size: 42px;
  font-family: "Times New Roman", Times, serif;
}

.pending h5 {
  font-size: 20px;
  padding: 10px;
  font-family: "Times New Roman", Times, serif;
}

.pending h6 {
  font-size: 15px;
  font-family: "Times New Roman", Times, serif;
}

.optiocheckbox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.agedivigm {
  margin-left: 40%;
  text-align: center;
}

.agedivigm .agentimg {
  width: 30vh;
  background: #f8f9fa;
  height: 30vh;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.agentimg img {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

.ageidbox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #f4f6f9;
}

.aguserid {
  background: #00bf72;
  height: 7vh;
  border-radius: 4px;
}

/* ===============SelectCatSlider end ============== */

.mobileCart {
  display: none;
}

.marquediv h6 {
  font-size: 1.1rem;
  font-weight: 600;
  color: red;
  width: 33%;
}

@media screen and (max-width: 768px) {
  .footermobile {
    display: none !important;
  }

  .marquediv h6 {
    width: 100%;
    margin: 0 5vh;
  }

  .bottompadd {
    margin-bottom: 12vh;
  }

  .search-box .serchsugist {
    width: 83%;
  }

  .noproductconta {
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .noproductconta .noproductImaage {
    width: 70%;
    height: 80%;
  }
}

@media (max-width: 1199px) {
  header .top-nav .navbar-top .mobile .search-box {
    width: 100%;
  }

  .search-box .serchsugist {
    width: 60%;
  }

  /* header .top-nav .navbar-top .dextop .search-box .input-group{
      display: flex;
      flex-wrap: nowrap;
  } */
}

@media (max-width: 1080px) {
  .header-nav .manmobile {
    display: none !important;
  }

  .search-box .serchsugist {
    width: 57%;
  }
}

@media (max-width: 1030px) {
  .search-box .serchsugist {
    width: 60%;
  }
}

@media (max-width: 991px) {
  .search-box .serchsugist {
    width: 83%;
  }
}

@media (max-width: 920px) {
  .search-box .serchsugist {
    width: 67%;
  }
}

@media (max-width: 767px) {
  header .top-nav .navbar-top .mobile {
    width: 100%;
  }

  .search-box .serchsugist {
    width: 83%;
  }
}

@media (max-width: 769px) {
  .header-nav .manmobile {
    display: block !important;
  }

  .menuoffdrop {
    position: relative;
  }

  .menuoffdrop .menulog {
    position: absolute;
    bottom: 10;
    left: 10;
  }

  .menuoffdrop .menulog li {
    color: #4a5568;
    font-weight: 700;
  }

  .search-box .serchsugist {
    width: 78.7%;
  }

  .laptopversioncart {
    display: none;
  }

  .mobileCart {
    display: block;
    height: 20vh;
    width: 100%;
    margin: 1vh 0;
    /* border-bottom: 1px solid #555; */
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 5px;
  }

  .MiddleCart {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 90%;
    width: 100%;
    /* background: yellow; */
  }

  .ImageContainer {
    height: 100%;
    width: 50%;
    /* background: red; */
  }

  .detailContainer {
    height: 100%;
    width: 50%;
    margin-left: 5%;
    /* background: green; */
  }

  .productName {
    font-weight: bold;
  }

  .subtitle {
    color: #0da487;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .button1 {
    background-color: #e3e0e0;
    border-radius: 100%;
    /* border-color: white; */
    height: 30px;
    width: 30px;
    /* padding-right: 20px; */
    display: flex;
    justify-content: center;
    align-items: center;
    outline: inherit;
    border: none;
  }

  .middlepart {
    border-color: white;
    background-color: white;
    height: 30px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  /* .CartRight {
    display: flex;
    justify-content: space-between;
    width: 100%;
  } */
  .cart-itemName {
    font-size: 14px;
    font-weight: 700;
    color: #555;
  }

  .quantity {
    display: flex;
    flex-direction: row;
    width: 150px;
  }

  .price {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
  }

  .remove1 {
    color: red;
    margin-left: 10px;
    font-size: 25px;
  }

  .PriceTitle {
    font-size: 16px;
  }

  .priceAmount {
    font-size: 14px;
    color: black;
  }

  .DisAmount {
    font-size: 12px;
    color: #111;
  }

  .ImageContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .ImageContainer .imagecart {
    width: 30%;
  }

  /* display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #4a5568;
  font-weight: 700 */
}

@media screen and (max-width: 600px) {
  .secomain .secconta {
    width: 100%;
    height: 5vh;
  }

  .search-box .serchsugist {
    width: 83.7%;
  }

  .secconta ul {
    width: 70%;
    justify-content: space-between;
    margin-left: -8%;
    margin-top: 4%;
  }

  .secconta ul li {
    padding: 0;
    font-size: 13px;
  }

  .footermobile {
    display: none !important;
  }
}

.customeButton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 7px 11px;
  background-color: #0e947a;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.checkbox-wrapper-6 .tgl {
  display: none;
}

.checkbox-wrapper-6 .tgl,
.checkbox-wrapper-6 .tgl:after,
.checkbox-wrapper-6 .tgl:before,
.checkbox-wrapper-6 .tgl *,
.checkbox-wrapper-6 .tgl *:after,
.checkbox-wrapper-6 .tgl *:before,
.checkbox-wrapper-6 .tgl + .tgl-btn {
  box-sizing: border-box;
}

.checkbox-wrapper-6 .tgl::-moz-selection,
.checkbox-wrapper-6 .tgl:after::-moz-selection,
.checkbox-wrapper-6 .tgl:before::-moz-selection,
.checkbox-wrapper-6 .tgl *::-moz-selection,
.checkbox-wrapper-6 .tgl *:after::-moz-selection,
.checkbox-wrapper-6 .tgl *:before::-moz-selection,
.checkbox-wrapper-6 .tgl + .tgl-btn::-moz-selection,
.checkbox-wrapper-6 .tgl::selection,
.checkbox-wrapper-6 .tgl:after::selection,
.checkbox-wrapper-6 .tgl:before::selection,
.checkbox-wrapper-6 .tgl *::selection,
.checkbox-wrapper-6 .tgl *:after::selection,
.checkbox-wrapper-6 .tgl *:before::selection,
.checkbox-wrapper-6 .tgl + .tgl-btn::selection {
  background: none;
}

.checkbox-wrapper-6 .tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 4em;
  height: 2em;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-wrapper-6 .tgl + .tgl-btn:after,
.checkbox-wrapper-6 .tgl + .tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}

.checkbox-wrapper-6 .tgl + .tgl-btn:after {
  left: 0;
}

.checkbox-wrapper-6 .tgl + .tgl-btn:before {
  display: none;
}

.checkbox-wrapper-6 .tgl:checked + .tgl-btn:after {
  left: 50%;
}

.checkbox-wrapper-6 .tgl-light + .tgl-btn {
  background: #6c757d;
  border-radius: 2em;
  padding: 2px;
  transition: all 0.4s ease;
}

.checkbox-wrapper-6 .tgl-light + .tgl-btn:after {
  border-radius: 50%;
  background: #fff;
  transition: all 0.2s ease;
}

.checkbox-wrapper-6 .tgl-light:checked + .tgl-btn {
  background: #0da487;
}

.loadingMain {
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cssmarquee {
  height: 30px;
  /* overflow: hidden; */
  /* position: relative; */
}

.cssmarquee h1 {
  font-size: 2em;
  color: turquoise;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 30px;
  text-align: center;
  transform: translateX(100%);
  animation: cssmarquee 15s linear infinite;
}

@keyframes cssmarquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 550px) {
  .search-box .serchsugist {
    width: 100%;
  }

  .ImageContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .ImageContainer .imagecart {
    width: 40%;
  }
}

@media (max-width: 450px) {
  .search-box .serchsugist {
    width: 100%;
  }

  .remove1 {
    color: red;
    margin-left: 10px;
    font-size: 25px;
  }

  .PriceTitle {
    font-size: 16px;
  }

  .priceAmount {
    font-size: 19px;
    color: black;
  }

  .DisAmount {
    font-size: 16px;
    color: #111;
  }

  .ImageContainer {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .ImageContainer .imagecart {
    width: 70%;
    border-radius: 10px;
  }
}

@media (max-width: 376px) {
  .search-box .serchsugist {
    width: 100%;
  }

  .ImageContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .ImageContainer .imagecart {
    width: 60%;
  }
}

.swipermaincontainer {
  background-color: "#fff";
  padding: 8px;
  /* box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; */
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  /* width: 97%; */
}

.swipersubcontainer {
  display: flex;
  align-items: center;
  border-bottom: 1px dashed #3c006b;
  padding: 8px;
}

.swipersubcontainer1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 10px;
}

.swipersubcontainer2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.swipersubcontainer3 {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swipersubcontainer4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;

  padding: 10px;
}

.sliderbtn {
  border: 1px solid #0e947a;
  height: 30px;
  padding: 3px;
  border-radius: 5px;
  width: 63px;
  background-color: #0e947a;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sliderlastbtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sliderlastbtn1 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
}

.footermaincontainer {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 15px;
}

.footercontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subfootercontainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.subfootercontainer1 {
  display: flex;
  justify-content: flex-start;
}

.textfieldcontainer {
  margin-bottom: 8px;
}

.textfield {
  width: 100%;
}

.textfield .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-radius: 10px;
  border-color: #0e947a;
  border-width: 0.3px;
  /* border-bottom-color:  #0e947a; */
}

.textfield .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #0e947a;
  border-radius: 10px;
  border-width: 0.3px;
  /* border-bottom-color:  #0e947a; */
}

.textfield .MuiInputLabel-root.Mui-focused {
  color: #0e947a;
}

.login-image {
  display: none;
}

@media screen and (max-width: 576px) {
  .login-image {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .product-section .right-box-contain .name {
    font-weight: 500;
  }
  #pro {
    gap: 10px;
  }
}

/* product Data Css */

* {
  padding: 0;
  margin: 0;
}

body {
  width: 100vw;
  overflow: hidden scroll;
}
body::-webkit-scrollbar{
  display: none;
}
.resultMain {
  width: 100%;
  height: 80vh;
  /* -color: blue; */
  /* padding-left: 10rem; */
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}

.resultList{
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
}

.resultimg {
  width: 32%;
  height: 100%;
  /* -color: navy; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: justify;
}

.resultimg > img {
  width: 181px;
  height: 181px;
  border-radius: 10px;
  box-shadow: rgba(151, 215, 41, 0.503) -10px -10px;
  object-fit: cover;
}

.resultimg > h1 {
  font-size: 1.2rem;
  color: rgb(109, 165, 35);
  margin: 5px;
}

.resultimg > p {
  color: #1d1d1d;
  font-size: 1rem;
  width: 100%;
}

.detailsContainer {
  width: 60%;
  /* padding-left: 10rem; */
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.detailsKeywords {
  width: 100%;
  height: 8vh;
  justify-content: center;
  gap: 2rem;
  overflow: scroll hidden;
}

.detailsKeywords::-webkit-scrollbar {
  display: none;
}

.innerdetails {
  width: 130%;
  display: flex;
  gap: .5rem;
}

.innerdetails > div {
  width: 20rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
}

.contentDivision {
  width: 100%;
}

.keyIngredientsContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  /* -color: navy; */
}

.contentContainer {
  width: 48%;
  display: flex;
  gap: 0.6rem;
  justify-content: center;
  align-items: center;
}

.contentContainer > img {
  width: 120px;
  height: 100px;
  border-radius: 100%;
  object-fit: cover;
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 6px;
}

.contentContainer > p {
  text-align: justify;
}

.product_title {
  color: #1d1d1d;
  font-size: 1.06rem;
  font-weight: 600;
  font-style: "open-san", "san-serif";
}

.usinglist {
  list-style: none;
}
.container_main1 {
  width: 100%;
  /* -color: green; */
  display: flex;
}
.container1{
  width: 50%;
  display: flex;
}
.container2_demo {
  width: 50%;
  height: 100%;
  /* -color: black; */
  display: flex;

  gap: 0.52rem;
}

.imgcontainer {
  width: 12.5rem;
  height: 13rem;
  border: 2px solid rgb(130, 195, 65);
}
.imgcontainer1 {
  width: 12.5rem;
  height: 14rem;
  border: 2px solid rgb(240, 68, 56);
}

.listitems {
  list-style: none;
  width: 100%;
  padding: 0;
}
.listitems1 {
  list-style: none;
  width: 100%;
  padding: 0;
}
.listitems1 > li {
  display: flex;
  gap: 0.3rem;
  color: #777777;
}
.listitems > li {
  display: flex;
  gap: 0.3rem;
  color: #777777;

}
.tickmark {
  color: rgb(130, 195, 65);
}
/* //rgb(240, 68, 56) x */

.Crossmark {
  color: rgb(240, 68, 56);
}

/* Faq */
.page {
  padding: 1rem;
  
  width: 100%;
  display: flex;
  justify-content: center;
}
.subpage {
  width: 80%;
  -color: #fff;
  /* color: #a6a6a6; */

}

/* .line{
  width: 8rem;
  height:4px;
  -color: #7b7878;
  align-items:center ;
  
} */
.heading {
  width: 8rem;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
}
.demo1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.heading::after {
  /* width:7rem; */
  content: "";
  height: 0.15rem;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgb(147, 147, 147) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  display: flex;
  /* justify-content: center;
  align-items: center ; */
  margin-bottom: 0.1rem;
  margin-top: 0.1rem;
}
#demo {
  margin-top: 2rem;
}
.ant-collapse-header-text {
  font-size: 1.05rem !important;
}
.Main {
  padding: 1rem;
  width: 100%;
}
.subpage {
  width: 100%;
  -color: #fff;
}
.page > h5 {
  font-size: x-large;
}
.data1 > h4 {
  font-size: 1.12rem;
  font-weight: 400;
}
.data1:hover {
  color: green;
}
.data1 > p {
  font-size: 0.75rem;
  font-weight: lighter;
}
.Data {
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 100%;
}
.data1 {
  width: 90%;
  justify-content: center;
  display: flex;
  justify-content: space-between;
}
.icons {
  font-size: xx-large;
  color: rgb(0, 175, 239);
}
.BenifitsContainer{
  width: 100%;
  /* padding-left: 10rem; */
}
/* mobile version */
@media screen and (max-width: 450px) {

  * {
    padding: 0;
    margin: 0;
  }
  .data1 h4{
    font-size:0.9rem;
  }
.header h4{
  font-size:1rem !important;
}
  .product_description{
    width:100% !important;
    padding:0.2rem;
     text-align:justify;
    }
    .product_description_inner1{
    width:100% !important;
    margin-top:10px;
    }
    .product_description_inner1 p{
      width:100% !important;
    }
    .product_description_inner2{
      width:100%;
      margin-top:10px;
    }
    
  .resultMain {
    padding: 1rem;
    width: 100%;
     height:auto;
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    /* overflow: hidden; */
  }
  .resultMain > h2 {
    font-size: 1.5rem;
  }
  .resultList {
    /* position: relative; */
    width: 100%; 
    height: fit-content;
 display: flex;
    flex-direction: column;
    /* -color: navy; */
  }
  .resultList .resultimg {
    /* -color: blue; */
    width: 100%;
    margin-top: 1rem;
   /* min-height:  90vh; */
  }
  .detailsContainer {
    /* padding-left: 1rem; */
    /* padding-right: 1rem; */
    width: 100% !important ;
    /* margin-top: 30rem; */
    /* -color: blue; */
  }
  .detailsKeywords {
    width: 100%;
    height: 9vh;
    justify-content: center;
    gap: 2rem;
    overflow: scroll hidden;
  }
  .innerdetails {
    width: 300%;
    display: flex;
    gap: 2rem;
  }
  .innerdetails > div {
    width: 20rem;
    height: 3rem;
  }
  .contentDivision {
    width: 100%;
  }
  .contentContainer {
    width: 100%;
    display: flex;
    gap: 0.6rem;
  }
  .contentContainer > p {
    text-align: justify;
  }
  .container_main1 {
    width: 100%;
    /* -color: green; */
    display: flex;
  }
  .container_main1 .container1,
  .container_main1 .container2_demo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .container1 {
    width: 50%;
  }
  .imgcontainer ,.imgcontainer1{
    width: 140px;
  }
  .Main {
    width: 100% !important;
    padding: 0;
  }
  .subpage {
    width: 90% !important;
    padding: 0;
    
  }
  .page {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .Data{
    /* margin-top: 2rem; */
    padding-left: 1rem;
    display: flex;
    gap: 1rem;
  }
  .data1{
    width: 100%;
  }
  .menandwomen{
    padding-left: 1rem;
  }
  .menandwomen>ul{
    display: flex;
    flex-direction: column;
    list-style: disc;
  }
  /* .BenifitsContainer{
    padding-left: 1rem;
  } */
  .BenifitsContainer video{
    width: 20rem;
    height: 10rem;
  }
}

@media screen and (min-width: 450px) and (max-width: 900px) {
  * {
    padding: 0;
    margin: 0;
  }
  .product_description{
    width:100% !important;
    padding:1rem;
     text-align:justify;
    }
    .product_description_inner1{
    width:100% !important;
    margin-top:10px;
    }
    .product_description_inner1 p{
      width: 100% !important;
    }
    .product_description_inner2{
      width:100%;
      margin-top:10px;
    }
  .resultMain{
    padding-left: 1rem;
    padding-right: 1rem;
    /* padding-right: 1rem; */
    width: 120vw;
  }
  .resultList {
    width: 100%;
    height: 100%;
    /* -color: red; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .detailsContainer {
    /* width: 120vw; */
    width:100% !important;
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }
  
  .detailsKeywords{
    width: 100%;
    height: 10vh;
  }
  .contentDivision {
    width: 100%;
  }
  .Main {
    width: 100% !important;
    padding: 0.7rem;   
  }
  .mainPage{
    width:100% !important;
  }
  .subpage {
    width: 100% !important;
  }
  .page {
    /* padding: 1rem; */
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .menandwomen{
    padding-left: 1rem;
  }
  /* .BenifitsContainer{
    padding-left: 1rem;
  } */
  .BenifitsContainer video{
    width: 30rem;
    height: 15rem;
  }
}


@media screen and (min-width:900px) and (max-width:1400px) {
  .resultMain{
    /* padding-left: 1rem; */
    /* padding-right: 1rem; */
    width: 100vw;
    height: auto;
    overflow: hidden;
    }
  .resultList{
    width: 95%;
  }
  .resultimg{
    height: 100%;
  }
  .detailsContainer{
    width: 85%;
    padding-left: 1rem;
  }
  .detailsKeywords,.contentDivision{
    /* height: 10vh; */
    width: 100%;
  }
  .menandwomen{
    padding-left: 1rem;
  }
  .Main {
    padding: 0.7rem;
    width: 100%;
  }
  .subpage {
    width: 95%;
  }
  .page {
    /* padding: 1rem; */
    width: 100%;
    display: flex;
    justify-content: center;
  }
  /* .BenifitsContainer{
    padding-left: 1rem;
  } */
  .BenifitsContainer video{
    width: 30rem;
    height: 15rem;
  }
}
@media screen and (min-width:1400px) {
  .resultList{
    width: 60%;
  }
  /* .BenifitsContainer{
    padding-left: 10rem;
  } */
  .BenifitsContainer video{
    width: 30rem;
    height: 15rem;
  }
  .resultMain{
    width: 100%;
    height: auto;
  }
  .detailsContainer{
    width: 75%;
  }
  .detailsKeywords{
    width: 100%;
    gap: 1rem;
  }
  .container_main1{
    width: 100%;
  }
  .Main{
    width: 75%;
  }
  .Data{
    /* padding-left: 10rem; */
  }
  .data1{
    width: 100%;
  }
}


